@import "node_modules/bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "./ADMStyle.scss";
@import "./skins/skin-blue.scss";
@import "./style.scss";
@import "./plugins/react-tags/styles.css";
@import "./plugins/react-time-input/style.scss";
@import "./plugins/components//forms.scss";
@import "./plugins//components//standplaatsen.scss";

.filter {
  label {
    margin-top: 0px;
  }
  .gemiddelde {
    font-size: 20px;
    display: block;
    font-weight: bold;
    margin-top: 2px;
    @include media-breakpoint-down(sm) {
      display: inline-block;
      margin-left: 1rem;
    }
  }
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wizard {
  .setup-panel {
    li {
      background-color: #2d5335;
      border-bottom: 2px solid #2d5335;
      &:hover {
        background-color: #2d5335aa;
      }
      a {
        color: #fff;
        font-size: 1.1rem;
        width: 100%;
        display: block;
        height: 100%;
        &:hover,
        &:focus {
          background-color: transparent;
          color: #fff;
        }
      }
    }
    .active {
      background-color: #ffffff;
      border-top: 2px solid #2d5335;
      border-right: 2px solid #2d5335;
      border-left: 2px solid #2d5335;
      border-bottom: 0px;

      &:hover {
        background-color: #ffffff;
      }
      a {
        color: #2d5335;
        font-weight: bold;
        font-size: 1.1rem;
        &:hover,
        &:focus {
          background-color: transparent;
          color: #2d5335;
        }
      }
    }
  }
  form {
    border-left: 2px solid #2d5335;
    padding: 10px;
  }
}

.autocomplete-dropdown-container {
  background-color: #ced4da;
  margin-left: 3px;
  border-bottom: 2px solid #ced4da;
  border-left: 2px solid #ced4da;
  border-right: 2px solid #ced4da;
  .suggestion-item {
    padding: 2px;
  }
}
.app-search {
  margin-inline-end: auto;
  .input-group {
    width: 400px;
    background-color: #2d5335;
    input {
      background-color: #2d5335;
      color: #ffffff;
      border: 0px;
      &:focus {
        color: #ffffff;
        background-color: #234129;
      }
      &::placeholder {
        color: #ffffff; // Kleur van de placeholder-tekst
      }
    }
  }
  .hs-form-search-menu-content {
    width: 400px;
  }
}

.form-group {
  margin-bottom: 10px;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.content-wrapper {
  flex: 1;
}
.content-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.flex-container {
  display: flex;
  align-items: stretch;
  background-color: #f1f1f1;
}
.flex-item {
  -webkit-flex-grow: 1; /* Safari */
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}
.flex-item a {
  width: 100%;
}
.wysiwyg-editor {
  min-height: 200px;
}
form label {
  margin-top: 20px;
}
.badge-success {
  background-color: #b1bf41;
}
.badge-danger {
  background-color: $orange;
}
.badge-warning {
  background-color: $greentrans;
}
.btn-warning {
  color: #ffffff;
}

.actionbutton button {
  margin-left: 10px;
}
.card {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: none;
}
.card-header {
  background-color: #2d5335;
  color: white;
}

.box {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

@keyframes alert-color {
  from {
    background-color: rgb(255, 94, 0);
  }

  to {
    background-color: rgb(217 105 41);
  }
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.reserveringoz {
  font-size: 1.2rem;
  .image {
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      border-radius: 18px;
    }
  }
  .titel {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
  }
  .prijzen {
    table {
      font-size: 1rem;
    }
  }
}
.actionmodal {
  height: 100%;
  .card-body {
    justify-content: start; /* Horizontaal uitlijnen */
    align-items: center; /* Verticaal uitlijnen */
    display: flex;
    flex-direction: column; /* Verander de richting naar kolom */
  }
}

.nav-link {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}
.navbar-nav .nav-link.show {
  color: #ffffff;
}
.badge-secondary {
  background-color: #80cff5;
}
.react-autosuggest__suggestions-container {
  margin-left: 3px;
  .react-autosuggest__suggestions-list {
    padding: 0px;
    margin: 0px;
    list-style: none;
    .react-autosuggest__suggestion {
      padding: 10px;
      border: 1px solid #ced4da56;
      &:hover {
        background-color: #2d533538;
        cursor: pointer;
      }
    }
  }
}

.two-line-summary {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.one-line-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rodal-close {
  top: 26px;
  right: 25px;
}

.overlay-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 20px;
}

.progress {
  width: 80%;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #007bff;
  transition: width 0.1s linear;
}
