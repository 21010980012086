
  
    .react-autosuggest__container {
      width: 100%;
      position: relative;
    }
  
    .react-autosuggest__input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      transition: border-color 0.3s;
      
      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }
    }
  
    .react-autosuggest__suggestions-container {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: white;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      z-index: 10;
    }
  
    .react-autosuggest__suggestion {
      padding: 10px;
      cursor: pointer;
      transition: background 0.2s;
  
      &:hover {
        background: #f8f9fa;
      }
    }
  
    #searchclear {
      position: absolute;
      right: 10px;
      cursor: pointer;
      color: #888;
      transition: color 0.2s;
  
      &:hover {
        color: #333;
      }
    }
  
  